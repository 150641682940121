import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tenth-aniversary',
  templateUrl: './tenth-aniversary.component.html',
  styleUrls: ['./tenth-aniversary.component.scss'],
})
export class TenthAniversaryComponent implements OnInit {

   
  
  name: string;

  constructor(private modalCtrl: ModalController) {}


ngOnInit() {}
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }
}
