import { Component, HostListener } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public menus;
  constructor(
    private menuController: MenuController,
     public dataService : DataService,
     public router : Router,
     public plt : Platform

     ) {
    this.dataService.getMenu().subscribe((snapshot) => {
      this.menus = snapshot
    })
  }

  ngOnInit(){
    const width = this.plt.width()
    this.toggleMenu(width)
  }

  closeMenu(){
   this.menuController.close()
  }


  ionViewWillEnter(){
    this.menuController.enable(true, 'main')
  }

  goToUrl(url){
    this.router.navigateByUrl(url)
    this.closeMenu()
    }

    goTo(){
      this.router.navigate(['/login'])
      this.closeMenu()
    }
  

@HostListener('window:resize', ['$event'])
public onResize(event){
  const newWidth = event.target.innerWidth;
  
  
  this.toggleMenu(newWidth)
}

toggleMenu(width){
  if(width > 767){
    this.menuController.enable(false, 'main')

  }else{
    this.menuController.enable(true, 'main')
  }

  
}

}
