// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'somkolch',
    appId: '1:454861808182:web:0c0c39476d50554546ee97',
    databaseURL: 'https://somkolch.firebaseio.com',
    storageBucket: 'somkolch.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyB4uz6DJRuwm8qV9LUYvBhlDVjGif4keFI',
    authDomain: 'somkolch.firebaseapp.com',
    messagingSenderId: '454861808182',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
