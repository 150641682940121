import { Injectable } from '@angular/core';
import { collection, orderBy, collectionData, doc, docData, Firestore, limit, query } from '@angular/fire/firestore';
// import { orderBy } from 'firebase/firestore';

// import { } from "@angular/fire";
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public firestore : Firestore) { 
  }
  
      getMenu(){
        const colRef = query(collection(this.firestore, "navbar"), orderBy("sn","asc"))
        return collectionData(colRef)
      }

      getWebsiteDetails(){
       const docRef = doc(this.firestore, "website", 'data')
       return docData(docRef) 
      }

      getBlogs(){
        const colRef = query(collection(this.firestore, "blog"), orderBy("date","desc"), limit(4))
        return collectionData(colRef)

      }


      getProducts(){
        const colRef = collection(this.firestore, 'products')
        return collectionData(colRef)
      }
}
